import React from 'react';
import styled from 'styled-components';
import { theme } from './theme';
import YouTube from 'react-youtube';

const Header = styled.header`
  margin-bottom: 1rem;
`;

const Role = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: ${theme.colors.text};

  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 1.2rem; /* slightly smaller on mobile */
  }
`;

const DateRange = styled.p`
  margin: 0;
  font-size: 0.9rem;
  text-align: center;
  font-weight: 550;
  color: ${theme.colors.text};

  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 0.6rem; /* slightly smaller on mobile */
  }
`;

const TechStackContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 1rem; /* slightly smaller on mobile */
  }
`;

const TechList = styled.ul`
  list-style-type: none;
  padding: 0;
  gap: 0.5rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.9rem;
  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 0.6rem;
  }
`;

const TechItem = styled.li`
  color: ${theme.colors.text};
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  flex-wrap: nowrap;
  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 0.60rem;
    padding: 0.10rem 0.25rem;
  }
`;

const AccomplishmentsContainer = styled.div`
    text-align: center;
    font-size: 1.2rem;
    @media (max-width: ${theme.breakpoints.tablet}) {
      font-size: 1rem; /* slightly smaller on mobile */
    }
`;

const AccomplishmentsList = styled.ul`
  list-style-type: disc;
  text-align: center;
`;

const BulletItem = styled.li`
  color: ${theme.colors.text};
  margin-bottom: 0.5rem;
  text-align: left;
  align-items: center;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.tablet}) {
    font-size: 0.75rem;
  }
`;

const HorizontalBar = styled.div`
  width: 100%;
  height: 1.5px;
  background: #ccc;
  margin-bottom: 1rem;
`;

const HorizontalBarInner = styled.div`
  margin-left: 3rem;
  margin-right: 3rem;
  height: 1.5px;
  background: #ccc;
  margin-bottom: 1rem;
`;

const RichContent = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    p {
        color: gray;
        font-size: .75rem;
        text-align: center;
        @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: 0.6rem;
        }
    }

    iframe {
        width: 100%;
        height: 20em;
        @media (max-width: ${theme.breakpoints.tablet}) {
            height: 8em;
        }
    }
`;

const ExperienceSection = ({
  role,
  dateRange,
  techStack = [],
  accomplishments = [],
  details,
}) => {
  return (
    <>
      <HorizontalBar />
      <RichContent>
        {details}
      </RichContent>
      <Header>
        <Role>{window.innerWidth < theme.breakpoints.tablet ? role : role.split(':').map((part, index) => (<p key={index} style={{fontSize: index > 0 ? "0.8rem" : "1.2rem"}}>{part}</p>))}</Role>
        {dateRange && <DateRange>{dateRange}</DateRange>}
      </Header>
      
      {techStack.length > 0 && (
        <TechStackContainer>
          <strong>Technical Expertise</strong>
          <TechList>
            {techStack.map((tech, index) => (
              <TechItem key={index}>
                {tech}
              </TechItem>
            ))}
          </TechList>
        </TechStackContainer>
      )}

      {accomplishments.length > 0 && (
        <AccomplishmentsContainer>
            <strong>Key Accomplishments</strong>
          <AccomplishmentsList>
            {accomplishments.map((item, index) => (
              <BulletItem key={index}>
                {item}
              </BulletItem>
            ))}
          </AccomplishmentsList>
        </AccomplishmentsContainer>
      )}
    </>
  );
};

export default ExperienceSection;
