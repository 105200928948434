import React, { useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import expData from './expData.json';
import ExperienceSection from "./Experience";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Keyframe for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const hexToRgba = (hex, alpha) => {
  const match = hex.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16));
  return `rgba(${match[0]}, ${match[1]}, ${match[2]}, ${alpha})`;
};

const WorkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }

`;

const Tabs = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: start;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0rem;
  overflow-x: hidden;
  margin: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    width: 20%;
    justify-content: start;
  }

  button {
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.textOnPrimary};
    padding: 1rem;
    cursor: pointer;
    font-size: 1rem;
    white-space: nowrap;

    &:hover {
      background-color: ${(props) => hexToRgba(props.theme.colors.accent, 0.5)};
    }

    &.active {
      font-weight: bold;
      border-left: 4px solid ${(props) => props.theme.colors.secondary};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      &.active {
        border-left: none;
        border-bottom: 4px solid ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

const Content = styled.div`
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: center;
  align-items: center;
  overflow-y: none;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  ${(props) =>
    props.isFadingOut
      ? css`
          animation: ${fadeOut} 0.3s ease-out forwards;
        `
      : css`
          animation: ${fadeIn} 0.4s ease-in forwards;
        `
  }

  h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
  }
`;

const WorkDetails = styled.div`
line-height: 1.5;
text-align: left;
display: flex;
min-height: 100vh;
width: 100%;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

const Logo = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 4rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: 3rem;
  }
`;

const AboutMe = () => {
  const experiences = [
    { 
        id: 1,
        logo: <Logo><FontAwesomeIcon icon="fa-solid fa-hand-holding-heart" /></Logo>,
        topic: "Community Engagement",
        details: ""
    },
    { 
        id: 2,
        logo: <Logo><FontAwesomeIcon icon="fa-solid fa-gamepad" /></Logo>,
        topic: "Video Game Enthusiast",
        details: ""
    },
    { 
        id: 3,
        logo: <Logo><FontAwesomeIcon icon="fa-solid fa-film" /></Logo>,
        topic: "Extreme Cinephilia",
        details: "" 
    },
    { 
        id: 4,
        logo: <Logo><FontAwesomeIcon icon="fa-solid fa-book" /></Logo>,
        topic: "Bookworm",
        details: "" 
    },
    { 
        id: 5,
        logo: <Logo><FontAwesomeIcon icon="fa-solid fa-plane" /></Logo>,
        topic: "Food & Travel",
        details: "" 
    }
  ];

  const tabRefs = useRef([]);
  const [activeTab, setActiveTab] = useState(experiences[0].id);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleTabClick = (id) => {
    if (id !== activeTab) {
      setIsFadingOut(true); // Trigger fade-out
      setTimeout(() => {
        setActiveTab(id); // Update tab after fade-out
        setIsFadingOut(false); // Trigger fade-in
      }, 300); // Match fade-out duration
    }
  };

  return (
    <WorkContainer>
      <Tabs>
        {experiences.map((exp, index) => (
          <button
            key={exp.id}
            ref={(el) => (tabRefs.current[index] = el)}
            className={activeTab === exp.id ? "active" : ""}
            onClick={() => handleTabClick(exp.id)}
          >
            {exp.logo}
          </button>
        ))}
      </Tabs>
      <Content isFadingOut={isFadingOut}>
        {experiences
          .filter((exp) => exp.id === activeTab)
          .map((exp) => (
            <div key={exp.id}>
              <h3>{exp.logo}</h3>
              <h3>{exp.topic}</h3>
              {exp.details}
            </div>
          ))}
      </Content>
    </WorkContainer>
  );
};

export default AboutMe;
