import React, { useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import expData from './expData.json';
import ExperienceSection from "./Experience";

// Keyframe for fade-in effect
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const hexToRgba = (hex, alpha) => {
  const match = hex.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16));
  return `rgba(${match[0]}, ${match[1]}, ${match[2]}, ${alpha})`;
};

const WorkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }

`;

const Tabs = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: start;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 0rem;
  overflow-x: hidden;
  margin: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    width: 20%;
    justify-content: start;
  }

  button {
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.textOnPrimary};
    padding: 1rem;
    cursor: pointer;
    font-size: 1rem;
    white-space: nowrap;

    &:hover {
      background-color: ${(props) => hexToRgba(props.theme.colors.accent, 0.5)};
    }

    &.active {
      font-weight: bold;
      border-left: 4px solid ${(props) => props.theme.colors.secondary};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      &.active {
        border-left: none;
        border-bottom: 4px solid ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

const Content = styled.div`
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: center;
  align-items: center;
  overflow-y: none;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  ${(props) =>
    props.isFadingOut
      ? css`
          animation: ${fadeOut} 0.3s ease-out forwards;
        `
      : css`
          animation: ${fadeIn} 0.4s ease-in forwards;
        `
  }

  h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1rem;
  }
`;

const WorkDetails = styled.div`
line-height: 1.5;
text-align: left;
display: flex;
min-height: 100vh;
width: 100%;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

const Logo = styled.div`
  width: 100%;
  height: 1.2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: .5rem;
  margin-bottom: .5rem;

  .img-sm {
    width: 4em;
  }
  
  .img-lg {
    width: 5em;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      height: .5rem;
      width: auto;
  }
`;

const WorkExp = () => {
  const experiences = [
    { 
      id: 1,
      logo: <Logo><svg id="toyota-mark" width="38" height="88" viewBox="0 0 78 58"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="toyota-mark_fill" fill-rule="nonzero"><polygon id="Path" fill="#D62328" points="0 0 0 19.6714286 28.1908571 19.6714286 28.1908571 67.32 47.8622857 67.32 47.8622857 19.6714286 38.0314286 19.6714286 38.0314286 0"></polygon> <polygon id="Path" fill="#A1C8DB" points="77.4325714 0 67.6017143 0 67.6017143 9.83085714 77.4325714 9.83085714"></polygon> <polygon id="Path" fill="#EC008C" points="47.8622857 0 38.0314286 0 38.0314286 9.83085714 47.8622857 9.83085714"></polygon> <polygon id="Path" fill="#D62328" points="57.6931429 9.83085714 47.8622857 9.83085714 47.8622857 19.6617143 57.6931429 19.6617143"></polygon> <polygon id="Path" fill="#C40380" points="42.9371429 14.7462857 38.0217143 14.7462857 38.0217143 19.6617143 42.9371429 19.6617143"></polygon> <polygon id="Path" fill="#AEAF2F" points="67.592 9.83085714 62.6765714 9.83085714 62.6765714 14.7462857 67.592 14.7462857"></polygon></g></g></svg></Logo>,
      company: "Toyota Connected North America",
      details: <>
        <iframe src="https://www.youtube.com/embed/7UkyOjeDloM?si=R_SPWbr9JEg3w996&amp;clip=UgkxahDj5CW3XRgeOIyecVeIEiF3zaNKxDGl&amp;clipt=EJjSShj4pk4" title="TCNA Product Demo" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>Demo of the AI product made with the help of my contributions below</p>
      </>
    },
    { 
      id: 2,
      logo: <Logo><img className="img-lg" src="logo4_sm.png" /></Logo>,
      company: "University of Texas at San Antonio",
      details: <>
        <iframe src="https://www.youtube.com/embed/2WYhmaR3PDU?si=XGzNSl7I_ld83iyF" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>Virtual presentation of ECCV-W pubplication</p>
      </>
    },
    { 
      id: 3,
      logo: <Logo><img className="img-lg" src="snc-logo.svg" /></Logo>,
      company: "Sierra Nevada Corporation",
      details: "" 
    },
    { 
      id: 4,
      logo: <Logo><img className="img-sm" src="funnelai-logo.png" /></Logo>,
      company: "FunnelAI",
      details: "" 
    },
  ];

  const tabRefs = useRef([]);
  const [activeTab, setActiveTab] = useState(experiences[0].id);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleTabClick = (id) => {
    if (id !== activeTab) {
      setIsFadingOut(true); // Trigger fade-out
      setTimeout(() => {
        setActiveTab(id); // Update tab after fade-out
        setIsFadingOut(false); // Trigger fade-in
      }, 300); // Match fade-out duration
    }
  };

  return (
    <WorkContainer>
      <Tabs>
        {experiences.map((exp, index) => (
          <button
            key={exp.id}
            ref={(el) => (tabRefs.current[index] = el)}
            className={activeTab === exp.id ? "active" : ""}
            onClick={() => handleTabClick(exp.id)}
          >
            {exp.logo}
          </button>
        ))}
      </Tabs>
      <Content isFadingOut={isFadingOut}>
        {experiences
          .filter((exp) => exp.id === activeTab)
          .map((exp) => (
            <div key={exp.id}>
              <h3>{exp.logo}</h3>
              <h3>{exp.company}</h3>
              <WorkDetails>
                <ExperienceSection {...expData[exp.id-1]} details={exp.details} />
              </WorkDetails>
            </div>
          ))}
      </Content>
    </WorkContainer>
  );
};

export default WorkExp;
