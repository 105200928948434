export const theme = {
  colors: {
    primary: "#2f5061",       // Google Blue
    secondary: "#d4f1f4",     // Google Green
    background: "#eeede7",    // Darker Gray
    text: "#202124",          // Black (Dark Text)
    textLight: "#FFFFFF",     // White (Light Text)
    accent: "#75e6da",        // Google Yellow (Accent)
  },
  breakpoints: {
    mobile: "768px",
    tablet: "1024px",
  },
};
