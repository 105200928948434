import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SocialLinks from "./SocialLiks";
import LandingPage from "./LandingPage";
import WorkExp from "./WorkExp";
import WorkExperience from "./WorkExperience";
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import { theme } from "./theme";
import ResearchPublications from "./ResearchPublications";
import { ownerWindow } from "@mui/material";

const hexToRgba = (hex, alpha) => {
  const match = hex.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16));
  return `rgba(${match[0]}, ${match[1]}, ${match[2]}, ${alpha})`;
};

const sampleProjects = [
  {
    name: 'fancy-regex/fancy-regex',
    description: 'A Rust library for compiling and matching regular expressions.',
    language: ['Rust'],
    stars: 0,
    lastUpdated: '10/2024',
    url: 'https://github.com/fancy-regex/fancy-regex'
  },
  {
    name: 'jonperry-dev/resume_project',
    description: 'This project is a web application designed to rank and parse Resume/CV as a utility for both candidate and recruiter.',
    language: ['JavaScript', 'Python', 'Docker', 'Shell'],
    stars: 0,
    lastUpdated: '12/2024',
    url: 'https://github.com/jonperry-dev/resume_project'
  },
  {
    name: 'UTSA-VAIL/EyeSeg',
    description: 'EyeSeg: Fast and Efficient Few-Shot Semantic Segmentation.',
    language: ['Python', 'Shell'],
    stars: 0,
    lastUpdated: '10/2022',
    url: 'https://github.com/UTSA-VAIL/EyeSeg'
  },
  {
    name: 'UTSA-VAIL/MinENet',
    description: 'MinENet: A Dilated CNN for Semantic Segmentation of Eye Features',
    language: ['Python', 'Shell'],
    stars: 0,
    lastUpdated: '12/2021',
    url: 'https://github.com/UTSA-VAIL/MinENet'
  }
];

const Layout = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: var(--app-height);
  }
`;

const Sidebar = styled.nav`
  width: 250px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  padding: 1rem 0;
  border-right: 5px solid ${(props) => props.theme.colors.secondary};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* Conditional transformation for mobile views */
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    transform: none; /* Always visible on larger screens */
    transition: none; /* No animations for desktop */
  }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 0.5rem;
      
      cursor: pointer; // Add cursor style
      transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
      border: 3px solid ${(props) => props.theme.colors.accent};

      &:hover {
        transform: scale(1.1); // Scale up on hover
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); // Add box shadow on hover

      }

      &:active {
        transform: scale(0.95); // Slightly reduce scale on click/touch
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); // Reduce box shadow on click/touch
      }
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
    }

    span {
      margin: 0.2rem 0;
      justify-content: center;
      display: flex;
    }
  }

  .social-links {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    
  }
`;

const Hamburger = styled.button`
  display: none;
  position: fixed;
  top: 4rem;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? "transparent" : hexToRgba(theme.colors.primary, 0.8)};
  border-radius: 30%;
  border: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  z-index: 11;
  padding: 0.5rem;
  cursor: pointer;
  line-height: 1;

  color: ${({ color }) => color};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.accent}; /* Always yellow on hover */
  }
`;

const Link = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  background-color: ${({ isActive }) => (isActive ? theme.colors.secondary : theme.colors.primary )};

  a {
    color: ${({ isActive }) => (isActive ? theme.colors.primary : theme.colors.textLight)};
    font-weight: bold;
    font-size: 1em;
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.colors.accent};
    }
  }
`;

const MainContent = styled.div`
  margin-left: 250px;
  flex: 1;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  scroll-snap-type: y mandatory; /* Enable smooth section snapping */
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};

  section {
    min-height: 100dvh; /* Full viewport height per section */
    scroll-snap-align: start; /* Align sections to the top */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0rem;
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1); /* Subtle section divider */
  }

  .links {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    display: block;
    background-color: #FFFF;
    font-family: 'Inter', sans-serif;

    a {
      color: ${(props) => props.theme.colors.text};
      font-size: 1em;
      transition: color 0.2s;
      cursor: pointer;

      &:hover {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-left: 0; /* Remove sidebar space */
    padding-top: 0rem; /* Add padding for better readability */
    
    section {
      padding: 0rem; /* Smaller padding for mobile */
      text-align: left; /* Align text for better readability on smaller screens */
      width: 100vw;
      height: var(--app-height);
    }
  }
`;


const LinkChild = ({ handleClick, bubbleUp, activeSection, section, content}) => {
  
  const handleChildClick = () => {
      handleClick(section)
      bubbleUp();
  };

  return <a className={activeSection === section ? "active" : ""} onClick={handleChildClick}>{content}</a>;
};


const Hero = () => {
    useEffect(() => {
      const preventScroll = (e) => {
          e.preventDefault();
          e.stopPropagation();
          return false;
      };

      // Add event listeners to block scroll events
      document.addEventListener("wheel", preventScroll, { passive: false });
      document.addEventListener("touchmove", preventScroll, { passive: false });
      document.addEventListener("keydown", (e) => {
          const keys = ["ArrowUp", "ArrowDown", "PageUp", "PageDown", "Home", "End", " "];
          if (keys.includes(e.key)) {
              preventScroll(e);
          }
      });

      return () => {
          // Remove event listeners when the component unmounts
          document.removeEventListener("wheel", preventScroll);
          document.removeEventListener("touchmove", preventScroll);
          document.removeEventListener("keydown", preventScroll);
      };
    }, []);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [hamburgerColor, setHamburgerColor] = useState(theme.colors.textLight); // Initial blue color
    const [activeSection, setActiveSection] = useState("home");
    const [activeId, setActiveId] = useState("");

    const toggleSidebar = (event) => {
      event.preventDefault(); // Prevent default behavior
      event.stopPropagation(); // Stop event propagation
  
      if (!isSidebarOpen) {
        setSidebarOpen(true);
        setHamburgerColor(theme.colors.textLight); // Set to yellow when open
      } else {
        setSidebarOpen(false);
        setHamburgerColor(theme.colors.textLight); // Reset to blue when closed
      }
    };
  
    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start", 
  
        });
        setActiveSection(id);
        console.log("Active Section:", activeSection);
        setSidebarOpen(false);
        setHamburgerColor(theme.colors.textLight);
      }
    };

    const handleLinkClick = (section) => {
      setActiveSection(section);
      scrollToSection(section); // Scroll to the section when the link is clicked
      console.log(section.concat("-id"))
      setActiveId(section.concat("-id"));
    };
  
    return (
      <Layout>
        <Hamburger
          isOpen={isSidebarOpen}
          color={hamburgerColor}
          onClick={toggleSidebar}      // Desktop clicks
          onTouchEnd={toggleSidebar}  // Mobile taps
        >
          {isSidebarOpen ? "✖" : "☰"}
        </Hamburger>
  
        <Sidebar isOpen={isSidebarOpen}>
          <div className="profile">
            <a onClick={() => handleLinkClick("home")}><img src="https://avatars.githubusercontent.com/u/125411632?v=4" alt="Profile" /></a>
            <span style={{fontFamily: "Inter", fontSize: "1.8em"}}>Jon Perry</span>
            <span style={{fontFamily: "Inter", fontSize: "1em"}}>Sr Machine Learning Engineer</span>
            <span style={{fontFamily: "Inter", fontSize: "0.7em"}}>@ Toyota Connected North America</span>
          </div>

          {/* Add the social links */}
          <div className="social-links">
            <SocialLinks />
           </div>
            <Link
              id="work-exp-id" 
              isActive={activeId === "work-exp-id"}
              onClick={() => setActiveId("work-exp-id")}
            >
              <LinkChild 
                handleClick={handleLinkClick}
                bubbleUp={setActiveId}
                content={"Professional Experience"}
                activeSection={activeSection}
                section={"work-exp"}
              />
            </Link>
            <Link
              id="projects-id"
              isActive={activeId === "projects-id"}
              onClick={() => setActiveId("projects-id")}
            >
              <a
                onClick={() => handleLinkClick("projects")}
                className={activeSection === "projects" ? "active" : ""}
              >
                
              </a>
              <LinkChild 
                handleClick={handleLinkClick}
                bubbleUp={setActiveId}
                content={"Projects & Open Source"}
                activeSection={activeSection}
                section={"projects"}
              />
            </Link>
            <Link
              id="research-id"
              isActive={activeId === "research-id"}
              onClick={() => setActiveId("research-id")}
            >
              <a
                onClick={() => handleLinkClick("research")}
                className={activeSection === "research" ? "active" : ""}
              >
                Research & Publications
              </a>
            </Link>
        </Sidebar>
        <MainContent>
          <section id="home">
            <LandingPage />
          </section>
          <section id="work-exp">
            <WorkExperience />
          </section>
          <section id="projects">
            <Projects projects={sampleProjects} />
          </section>
          <section id="research">
            <ResearchPublications />
          </section>
        </MainContent>
      </Layout>
    );
  };
  
  export default Hero;
  
