import styled from "styled-components";
import { FaLinkedin, FaGithub, FaInstagram, FaGoogle } from "react-icons/fa"; // Font Awesome icons
import { SiGooglescholar, SiResearchgate } from "react-icons/si"; // Specialized icons

const SocialLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns, equally spaced */
  grid-gap: 1rem; /* Spacing between icons */
  justify-items: center; /* Center icons in their grid cells */
  align-items: center; /* Vertically align icons */
  width: 100%; /* Full width of the sidebar */
  padding: 1rem;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.textLight};
    font-size: 2rem; /* Adjust icon size */
    transition: color 0.3s, transform 0.2s;

    &:hover {
      color: ${(props) => props.theme.colors.accent}; /* Change color on hover */
      transform: scale(1.1); /* Slight zoom effect */
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 per row on mobile */
    grid-gap: 0.5rem; /* Reduce spacing for smaller screens */

    a {
      font-size: 1.8rem; /* Slightly smaller icons for mobile */
    }
  }
`;




const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <a
        href="https://www.researchgate.net/profile/Jonathan-Perry-7"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="ResearchGate"
      >
        <SiResearchgate />
      </a>
      <a
        href="https://www.linkedin.com/in/perryjon"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <FaLinkedin />
      </a>
      <a
        href="https://github.com/jonperry-dev"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="GitHub"
      >
        <FaGithub />
      </a>
      <a
        href="https://www.instagram.com/jonperry.dev/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <FaInstagram />
      </a>
      <a
        href="mailto:jon@jonperry.dev"
        aria-label="Gmail"
      >
        <FaGoogle />
      </a>
      <a
        href="https://scholar.google.com/citations?user=OcyjbIkAAAAJ&hl=en"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Google Scholar"
      >
        <SiGooglescholar />
      </a>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
