import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from './theme';

// Example data
const publications = [
  {
    id: 1,
    title: "EyeSeg: Fast and Efficient Few-Shot Semantic Segmentation",
    authors: "Jonathan Perry, Amanda Fernandez",
    abstract: "Semantic segmentation is a key component in eye- and gaze- tracking for virtual reality (VR) and augmented reality (AR) applications. While it is a well-studied computer vision problem, most state-of-the-art models require large amounts of labeled data, which is limited in this specific domain. An additional consideration in eye tracking is the capacity for real-time predictions, necessary for responsive AR/VR interfaces. In this work, we propose EyeSeg, an encoder-decoder architecture designed for accurate pixel-wise few-shot semantic segmentation with limited annotated data. We report results from the OpenEDS2020 Challenge, yielding a 94.5% mean Intersection Over Union (mIOU) score, which is a 10.5% score increase over the baseline approach. The experimental results demonstrate state-of-the-art performance while preserving a low latency framework.",
    thumbnail: "eccvw2020_intro.png",
    features: ["Computer Vision", "Semantic Segmentation", "Deep Learning", "Semi-Surpervised", "Sparsely Labeled Data", "On-Device ML", "Low Latency Inference"]
  },
  {
    id: 2,
    title: "MinENet: A Dilated CNN for Semantic Segmentation of Eye Features",
    authors: "Jonathan Perry, Amanda Fernandez",
    abstract: "Fast and accurate eye tracking is a critical task for a range of research in virtual and augmented reality, attention tracking, mobile applications, and medical analysis. While deep neural network models excel at image analysis tasks, existing approaches to segmentation often consider only one class, emphasize classification over segmentation, or come with prohibitively high resource costs. In this work, we propose MinENet, a minimized efficient neural network architecture designed for fast multi-class semantic segmentation. We demonstrate performance of MinENet on the OpenEDS Semantic Segmentation Challenge dataset, against a baseline model as well as standard state-of-the-art neural network architectures-a convolutional neural network (CNN) and a dilated CNN. Our encoder-decoder architecture improves accuracy of multi-class segmentation of eye features in this large-scale high-resolution dataset, while also providing a design that is demonstrably lightweight and efficient.",
    thumbnail: "iccv2019_poster.jpg",
    features: ["Computer Vision", "Semantic Segmentation", "Deep Learning", "Low Latency Inference", "On-Device ML", "Surpervised Learning"]
  },
  {
    id: 3,
    title: "EyeSeg: Fast and Efficient Few-Shot Semantic Segmentation",
    authors: "Jonathan Perry, Amanda Fernandez",
    abstract: "Semantic segmentation is a key component in eye- and gaze- tracking for virtual reality (VR) and augmented reality (AR) applications. While it is a well-studied computer vision problem, most state-of-the-art models require large amounts of labeled data, which is limited in this specific domain. An additional consideration in eye tracking is the capacity for real-time predictions, necessary for responsive AR/VR interfaces. In this work, we propose EyeSeg, an encoder-decoder architecture designed for accurate pixel-wise few-shot semantic segmentation with limited annotated data. We report results from the OpenEDS2020 Challenge, yielding a 94.5% mean Intersection Over Union (mIOU) score, which is a 10.5% score increase over the baseline approach. The experimental results demonstrate state-of-the-art performance while preserving a low latency framework.",
    thumbnail: "eccvw2020_intro.png",
    features: ["Computer Vision", "Semantic Segmentation", "Deep Learning", "Semi-Surpervised", "Sparsely Labeled Data", "On-Device ML", "Low Latency Inference"]
  },
  {
    id: 4,
    title: "MinENet: A Dilated CNN for Semantic Segmentation of Eye Features",
    authors: "Jonathan Perry, Amanda Fernandez",
    abstract: "Fast and accurate eye tracking is a critical task for a range of research in virtual and augmented reality, attention tracking, mobile applications, and medical analysis. While deep neural network models excel at image analysis tasks, existing approaches to segmentation often consider only one class, emphasize classification over segmentation, or come with prohibitively high resource costs. In this work, we propose MinENet, a minimized efficient neural network architecture designed for fast multi-class semantic segmentation. We demonstrate performance of MinENet on the OpenEDS Semantic Segmentation Challenge dataset, against a baseline model as well as standard state-of-the-art neural network architectures-a convolutional neural network (CNN) and a dilated CNN. Our encoder-decoder architecture improves accuracy of multi-class segmentation of eye features in this large-scale high-resolution dataset, while also providing a design that is demonstrably lightweight and efficient.",
    thumbnail: "iccv2019_poster.jpg",
    features: ["Computer Vision", "Semantic Segmentation", "Deep Learning", "Low Latency Inference", "On-Device ML", "Surpervised Learning"]
  }
];

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  height: 100dvh;
  flex-direction: column;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.background};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #333;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: var(--app-height);
    padding: 1rem;
  }
`;

const Heading = styled.h1`
  margin: 0;
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  font-size: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: 0.6rem;
    }
  }
`;

/** Modern Card Styles */
const PublicationItem = styled.div`
  background: ${(props) => props.theme.colors.textLight};
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  box-shadow: 0 4px 14px rgba(0,0,0,0.07);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 2rem;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0,0,0,0.1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    padding: 0.5rem;
  }
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color:  ${(props) => props.theme.colors.text};
  line-height: 1.3;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1rem;
  }
`;

const Authors = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color:  ${(props) => props.theme.colors.text};
  font-style: italic;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0.7rem;
  }
`;

const Abstract = styled.p`
  margin: 0;
  text-align: left;
  font-size: .8rem;
  color:  ${(props) => props.theme.colors.text};
  line-height: 1.5;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0.6rem;
  }
`;

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const FeatureTag = styled.span`
  background:  ${(props) => props.theme.colors.primary};
  color:  ${(props) => props.theme.colors.textLight};
  font-size: 0.8rem;
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-weight: 600;
  white-space: nowrap;
`;

const ThumbnailContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    align-items: center;
    justify-content: center;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  transition: opacity 0.2s ease;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: 10em;
    object-fit: contain;
  }
  &:hover {
    opacity: 0.95;
  }
`;

const Navigation = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
  }
`;

const NavButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textLight};
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.primary};
  }

  &:disabled {
    background: #aaa;
    cursor: not-allowed;
  }
`;

const ExperienceListContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const ExperienceListWrapper = styled.div`
  transition: opacity 0.4s ease;
  will-change: opacity;
  opacity: ${props => props.opacity};
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
`;

/** Modular component for a single publication entry */
const PublicationCard = ({ title, authors, abstract, features = [], thumbnail }) => {
  return (
    <PublicationItem>
      <Content>
        <Title>{title}</Title>
        <Authors>{authors}</Authors>
        <Abstract>{abstract}</Abstract>
        {features.length > 0 && (
          <Features>
            {features.map((f, i) => (
              <FeatureTag key={i}>{f}</FeatureTag>
            ))}
          </Features>
        )}
      </Content>
      <ThumbnailContainer>
        <Thumbnail src={thumbnail} alt={`Poster preview for ${title}`} />
      </ThumbnailContainer>
    </PublicationItem>
  );
};

const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  background: #ccc;
`;

/** Main component that maps over the data and renders the publication list */
const ResearchPublications = () => {

  const listRef = useRef(null);
  const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth < 768 ? 1 : 1);
  const [opacity, setOpacity] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [pendingPage, setPendingPage] = useState(null); 

  const calculateItemsPerPage = useCallback(() => {
    if (!listRef.current) return;
    const containerHeight = listRef.current.clientHeight; 
    // Temporarily measure how many items fit
    const items = Array.from(listRef.current.children);
    if (items.length === 0) return;
    
    let fitCount = 0;
    let accumulatedHeight = 0;
    for (let item of items) {
      const rect = item.getBoundingClientRect();
      const itemHeight = rect.height;
      if ((accumulatedHeight + itemHeight) <= containerHeight) {
        accumulatedHeight += itemHeight;
        fitCount += 1;
      } else {
        break;
      }
    }
    setItemsPerPage(Math.max(window.innerWidth < 768 ? 1 : 1, fitCount));
    setCurrentPage(0); // Reset to first page on recalculation
  }, []);

  useEffect(() => {
    // Calculate after first render
    calculateItemsPerPage();
    // Recalculate on window resize
    window.addEventListener('resize', calculateItemsPerPage);
    return () => {
      window.removeEventListener('resize', calculateItemsPerPage);
    };
  }, [calculateItemsPerPage]);

  const totalPages = Math.ceil(publications.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const visibleItems = publications.slice(startIndex, startIndex + itemsPerPage);

  const handleNav = (direction) => {
    const newPage = direction === 'up' ? currentPage - 1 : currentPage + 1;
    if (newPage < 0 || newPage >= totalPages) return;
    setPendingPage(newPage);
    // Fade out first
    setOpacity(0);
  };

  const handleTransitionEnd = () => {
    // If opacity is 0 and we have a pending page, change the page and fade in
    if (opacity === 0 && pendingPage !== null) {
      setCurrentPage(pendingPage);
      setPendingPage(null);
      // Next paint cycle
      requestAnimationFrame(() => {
        setOpacity(1);
      });
    }
  };

  return (
    <Container>
      <Heading>
        <h1>Research Publications</h1>
        <p>Below are some highlights of my research efforts</p>
        <HorizontalBar />
      </Heading>
      <ExperienceListContainer ref={listRef}>
        <ExperienceListWrapper style={{ opacity }} onTransitionEnd={handleTransitionEnd}>
        {visibleItems.map((pub, index) => (
            <PublicationCard
                key={index}
                title={pub.title}
                authors={pub.authors}
                abstract={pub.abstract}
                features={pub.features}
                thumbnail={pub.thumbnail}
          />
        ))}
        </ExperienceListWrapper>
      </ExperienceListContainer>
      <Navigation>
        <NavButton onClick={() => handleNav('up')} disabled={currentPage === 0}><FontAwesomeIcon icon="fa-solid fa-arrow-up" /></NavButton>
        <NavButton onClick={() => handleNav('down')} disabled={currentPage >= totalPages - 1}><FontAwesomeIcon icon="fa-solid fa-arrow-down" /></NavButton>
      </Navigation>
    </Container>
  );
};

export default ResearchPublications;
