import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from './theme';

const SectionContainer = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background};
  color: #333;
  height: 100dvh;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 1rem;
    flex-direction: column;
    height: var(--app-height);
  }
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #111;
`;

const SectionDescription = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
`;

const ProjectsGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 2rem;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.07);
  }
`;

const RepoName = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #0366d6;
  word-break: break-all;
`;

const Description = styled.p`
  font-size: 0.8rem;
  color: #333;
  flex: 1;
  margin-bottom: 1rem;
`;

const Meta = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 1rem;
  justify-content: center;

  & > span {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
`;

const LanguageDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background: ${props => props.color || '#ccc'};
  border-radius: 50%;
`;

const Actions = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`;

const ViewLink = styled.a`
  text-decoration: none;
  color: #0366d6;
  font-weight: 600;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Navigation = styled.div`
  position: relative;

  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
  }
`;

const NavButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textLight};
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.primary};
  }

  &:disabled {
    background: #aaa;
    cursor: not-allowed;
  }
`;

const ProjectListContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

const ProjectListWrapper = styled.div`
  transition: opacity 0.4s ease;
  will-change: opacity;
  opacity: ${props => props.opacity};
  justify-content: center;
  align-items: center;
  width: 90%;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 98%;
  }
`;

const languageColors = {
  JavaScript: '#f1e05a',
  TypeScript: '#2b7489',
  Python: '#3572A5',
  Go: '#00ADD8',
  Rust: '#C45508',
  Makefile: '#427819',
  Docker: '#384d54',
  Shell: '#89e051',
  // Add more language-color mappings as needed
};

const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  background: #ccc;
`;

const Heading = styled.h1`
  margin: 0;
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  font-size: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: 0.6rem;
    }
  }
`;

const ProjectCard = ({ name, description, language, stars, lastUpdated, url }) => {
  return (
    <Card>
      <RepoName>{name}</RepoName>
      <Description>{description}</Description>
      <Meta>
        {language && language.map((lang, index) => (
          <span>
            <LanguageDot color={languageColors[lang]}/> {lang}
          </span>
        ))}
      </Meta>
      {url && (
        <Actions>
          <ViewLink href={url} target="_blank" rel="noopener noreferrer">
            View on GitHub →
          </ViewLink>
        </Actions>
      )}
    </Card>
  );
};

const Projects = ({ 
  title = "Projects & Open Source", 
  description = "A curated list of personal projects and open source contributions.", 
  projects = []
}) => {
  const listRef = useRef(null);
  const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth < 768 ? 2 : 3);
  const [opacity, setOpacity] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [pendingPage, setPendingPage] = useState(null); 

  const calculateItemsPerPage = useCallback(() => {
    if (!listRef.current) return;
    const containerHeight = listRef.current.clientHeight; 
    // Temporarily measure how many items fit
    const items = Array.from(listRef.current.children);
    if (items.length === 0) return;
    
    let fitCount = 0;
    let accumulatedHeight = 0;
    for (let item of items) {
      const rect = item.getBoundingClientRect();
      const itemHeight = rect.height;
      if ((accumulatedHeight + itemHeight) <= containerHeight) {
        accumulatedHeight += itemHeight;
        fitCount += 1;
      } else {
        break;
      }
    }
    setItemsPerPage(Math.max(window.innerWidth < 768 ? 2 : 3, fitCount));
    setCurrentPage(0); // Reset to first page on recalculation
  }, []);

  useEffect(() => {
    // Calculate after first render
    calculateItemsPerPage();
    // Recalculate on window resize
    window.addEventListener('resize', calculateItemsPerPage);
    return () => {
      window.removeEventListener('resize', calculateItemsPerPage);
    };
  }, [calculateItemsPerPage]);

  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const visibleItems = projects.slice(startIndex, startIndex + itemsPerPage);

  const handleNav = (direction) => {
    const newPage = direction === 'up' ? currentPage - 1 : currentPage + 1;
    if (newPage < 0 || newPage >= totalPages) return;
    setPendingPage(newPage);
    // Fade out first
    setOpacity(0);
  };

  const handleTransitionEnd = () => {
    // If opacity is 0 and we have a pending page, change the page and fade in
    if (opacity === 0 && pendingPage !== null) {
      setCurrentPage(pendingPage);
      setPendingPage(null);
      // Next paint cycle
      requestAnimationFrame(() => {
        setOpacity(1);
      });
    }
  };
  return (
    <SectionContainer>
      <Heading>
        <h1>{title}</h1>
        <p>{description}</p>
        <HorizontalBar />
      </Heading>
      <ProjectListContainer ref={listRef}>
        <ProjectListWrapper style={{ opacity }} onTransitionEnd={handleTransitionEnd}>
          {visibleItems.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </ProjectListWrapper>
      </ProjectListContainer>
      <Navigation>
        <NavButton onClick={() => handleNav('up')} disabled={currentPage === 0}><FontAwesomeIcon icon="fa-solid fa-arrow-up" /></NavButton>
        <NavButton onClick={() => handleNav('down')} disabled={currentPage >= totalPages - 1}><FontAwesomeIcon icon="fa-solid fa-arrow-down" /></NavButton>
      </Navigation>
    </SectionContainer>
  );
};

export default Projects;