import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from './theme';
// Example Data
const experiences = [
  {
    title: "Sr Machine Learning Engineer",
    company: "@ Toyota Connected North America",
    logo: <svg id="toyota-mark" width="38" height="88" viewBox="0 0 78 58"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="toyota-mark_fill" fill-rule="nonzero"><polygon id="Path" fill="#D62328" points="0 0 0 19.6714286 28.1908571 19.6714286 28.1908571 67.32 47.8622857 67.32 47.8622857 19.6714286 38.0314286 19.6714286 38.0314286 0"></polygon> <polygon id="Path" fill="#A1C8DB" points="77.4325714 0 67.6017143 0 67.6017143 9.83085714 77.4325714 9.83085714"></polygon> <polygon id="Path" fill="#EC008C" points="47.8622857 0 38.0314286 0 38.0314286 9.83085714 47.8622857 9.83085714"></polygon> <polygon id="Path" fill="#D62328" points="57.6931429 9.83085714 47.8622857 9.83085714 47.8622857 19.6617143 57.6931429 19.6617143"></polygon> <polygon id="Path" fill="#C40380" points="42.9371429 14.7462857 38.0217143 14.7462857 38.0217143 19.6617143 42.9371429 19.6617143"></polygon> <polygon id="Path" fill="#AEAF2F" points="67.592 9.83085714 62.6765714 9.83085714 62.6765714 14.7462857 67.592 14.7462857"></polygon></g></g></svg>,
    date: "2021 - Present",
    description: "Cloud & Embedded AI/ML For In-Vehicle UX.",
    highlights: [
      "Implemented & translated state-of-the-art research papers into production-ready code, including the development of uncertainty-aware evidential models supporting multiple NLP/NLU classification tasks.",
      "Built a Rust crate/package that served as a critical multi-faceted high touch component for post-prediction ML translation to business use cases saving countless hours of development time and ensuring quality UX.",
      "Led ML & cross-functional team growth through structured mentorship, knowledge sharing, and technical workshops. Fostering junior developer skills along with leveling up Product Owners and Linguists."
    ],
    thumbnail: <>
        <iframe src="https://www.youtube.com/embed/7UkyOjeDloM?si=R_SPWbr9JEg3w996&amp;clip=UgkxahDj5CW3XRgeOIyecVeIEiF3zaNKxDGl&amp;clipt=EJjSShj4pk4" title="TCNA Product Demo" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>Demo of the AI product made with the help of my contributions below</p>
    </>
  },
  {
    title: "Graduate AI/ML Researcher",
    company: "@ University of Texas at San Antonio",
    logo: <img className="img-lg" src="logo4_sm.png" />,
    date: "2020 - Present",
    description: "AI/ML Research Towards Explainability & Optimization.",
    highlights: [
      "Led the AI/ML research effort on a cross-functional team as a part of a large consortium of national labs and university labs aiming to develop novel state-of-the-art vision-based segmentation neural networks to optimize simulations and testing with nuclear materials.",
      "Implemented novel semantic segmentation algorithms, ML optimization techniques, and ML algorithms/analysis towards explainability of neural networks.",
      "Authored and published two peer-reviewed papers on advanced AI/ML methodologies, publicly showcasing these advancements through oral presentations contributing to the field's body of knowledge and establishing reputation for a newly formed research lab."
    ],
    thumbnail: <>
        <iframe src="https://www.youtube.com/embed/2WYhmaR3PDU?si=XGzNSl7I_ld83iyF" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p>Virtual presentation of ECCV-W publication</p>
    </>
  },
  {
    title: "Machine Learning Engineer",
    company: "@ Sierra Nevada Corporation (Internship)",
    logo: <img className="img-lg" src="snc-logo.svg" />,
    date: "Summer 2020",
    description: "Explored AI/ML Research in the Defense Space.",
    highlights: [
        "Applied Multi-Agent Reinforcement Learning techniques to a real-world military application towards radio frequency predictions.",
        "Utilized Keras-RL and OpenAI frameworks to performs iterations over episodes in a simulated environment.",
        "Collaborated with peers from various field's of expertise and background as a part of a diverse research & development team."
    ],
    thumbnail: null
  },
  {
    title: "Machine Learning Engineer",
    company: "@ FunnelAI (Angel Start-up)",
    logo: <img className="img-sm" src="funnelai-logo.png" />,
    date: "2019 - 2019",
    description: "Built AI/ML Infrastructure From Zero to Funded!",
    highlights: [
        "Established the core NLU/NLP infrastructure in TensorFlow served as a microservice for B2B SaaS application enabling the product and successful seed funding of $1.5M from Angel Investor.",
        "Collaborated with C-suite involving brainstorming and product ideation to evolve the ML platform fostering proper error type mitigation in ML modeling."
    ],
    thumbnail: null
  }
];

// Styled Containers
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  height: 100dvh;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: var(--app-height);
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
`;

const Header = styled.div`
  text-align: center;
  font-size: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: 0.6rem;
    }
  }
`;

const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 6rem;
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
            width: 4rem;
        }
    }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 15em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
  }
`;

const ExperienceItem = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 14px rgba(0,0,0,0.07);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 2rem;
  max-height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0,0,0,0.1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const ContentSection = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
`;

const TitleDateRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1rem;
  }
`;

const Company = styled.div`
  font-size: 0.75rem;
  font-weight: 550;
  text-align: center;
  margin: 0;
  padding: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0.5rem;
  }
`;

const DateRange = styled.div`
  font-size: 1rem;
  color: #888;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0.6rem;
  }
`;

const Description = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
  }
`;

const HighlightsList = styled.ul`
  list-style: disc;
  padding-left: 1rem;
`;

const HighlightItem = styled.li`
  font-size: 0.85rem;
  text-align: left;
  line-height: 1.5;
  padding-bottom: 1rem;
  color: #555;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 0.65rem;
  }
`;

const ThumbnailSection = styled.div`
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  align-self: center;
  
  @media(min-width: 768px) {
    width: 20em;
  }
`;

const ThumbnailImage = styled.div`
  width: 100%;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  height: auto;
  p {
    font-size: .5rem;
    text-align: center;
    align-items: center;
    padding-right: 2rem;
    padding-top: .5rem;
    padding-bottom: 0.5rem;
  }
`;

const Navigation = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const NavButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textLight};
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.primary};
  }

  &:disabled {
    background: #aaa;
    cursor: not-allowed;
  }
`;

const ExperienceListContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const ExperienceListWrapper = styled.div`
  transition: opacity 0.4s ease;
  will-change: opacity;
  opacity: ${props => props.opacity};
  justify-content: center;
  align-items: center;
`;

const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  background: #ccc;
`;

const HorizontalBarLight = styled.div`
  width: 100%;
  height: 0.5px;
  margin-top: 1rem;
  background: #ccc;
`;
const WorkExperience = () => {
  const listRef = useRef(null);
  const [itemsPerPage, setItemsPerPage] = useState(window.innerWidth < 768 ? 1 : 1);
  const [opacity, setOpacity] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [pendingPage, setPendingPage] = useState(null); 

  const calculateItemsPerPage = useCallback(() => {
    if (!listRef.current) return;
    const containerHeight = listRef.current.clientHeight; 
    // Temporarily measure how many items fit
    const items = Array.from(listRef.current.children);
    if (items.length === 0) return;
    
    let fitCount = 0;
    let accumulatedHeight = 0;
    for (let item of items) {
      const rect = item.getBoundingClientRect();
      const itemHeight = rect.height;
      if ((accumulatedHeight + itemHeight) <= containerHeight) {
        accumulatedHeight += itemHeight;
        fitCount += 1;
      } else {
        break;
      }
    }
    setItemsPerPage(Math.max(window.innerWidth < 768 ? 1 : 1, fitCount));
    setCurrentPage(0); // Reset to first page on recalculation
  }, []);

  useEffect(() => {
    // Calculate after first render
    calculateItemsPerPage();
    // Recalculate on window resize
    window.addEventListener('resize', calculateItemsPerPage);
    return () => {
      window.removeEventListener('resize', calculateItemsPerPage);
    };
  }, [calculateItemsPerPage]);

  const totalPages = Math.ceil(experiences.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const visibleItems = experiences.slice(startIndex, startIndex + itemsPerPage);

  const handleNav = (direction) => {
    const newPage = direction === 'up' ? currentPage - 1 : currentPage + 1;
    if (newPage < 0 || newPage >= totalPages) return;
    setPendingPage(newPage);
    // Fade out first
    setOpacity(0);
  };

  const handleTransitionEnd = () => {
    // If opacity is 0 and we have a pending page, change the page and fade in
    if (opacity === 0 && pendingPage !== null) {
      setCurrentPage(pendingPage);
      setPendingPage(null);
      // Next paint cycle
      requestAnimationFrame(() => {
        setOpacity(1);
      });
    }
  };
  return (
    <PageContainer>
      <Header>
        <h1>Work Experience</h1>
        <p>Below are some selected snippets of my work and accomplishments.</p>
      </Header>
      <HorizontalBar />
      <ExperienceListContainer ref={listRef}>
        <ExperienceListWrapper style={{ opacity }} onTransitionEnd={handleTransitionEnd}>
          {visibleItems.map((exp, index) => (
            <ExperienceItem key={index}>
              <ContentSection>
                
                <TitleDateRow>
                    <TitleWrapper>
                        <LogoWrapper>
                            <Logo>{exp.logo}</Logo>
                        </LogoWrapper>
                        <TitleContainer>
                            <Title>{exp.title}</Title>
                            <Company>{exp.company}</Company>
                            <DateRange>{exp.date}</DateRange>
                        </TitleContainer>
                    </TitleWrapper>
                </TitleDateRow>
                <Description>{exp.description}<HorizontalBar /></Description>
                <HighlightsList>
                  {exp.highlights.map((h, i) => (
                    <HighlightItem key={i}>{h}</HighlightItem>
                  ))}
                </HighlightsList>
              </ContentSection>
              { exp.thumbnail ? 
              <ThumbnailSection>
                <ThumbnailImage>{exp.thumbnail}</ThumbnailImage>
              </ThumbnailSection> : <></>}
            </ExperienceItem>
          ))}
        </ExperienceListWrapper>
      </ExperienceListContainer>
      <Navigation>
        <NavButton onClick={() => handleNav('up')} disabled={currentPage === 0}><FontAwesomeIcon icon="fa-solid fa-arrow-up" /></NavButton>
        <NavButton onClick={() => handleNav('down')} disabled={currentPage >= totalPages - 1}><FontAwesomeIcon icon="fa-solid fa-arrow-down" /></NavButton>
      </Navigation>
    </PageContainer>
  );
};

export default WorkExperience;
