import React, { useState } from "react";
import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  height: 100vh;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: var(--app-height);
    margin: 0;
    grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
  }

  img {
    object-fit: cover;
    overflow: hidden;
    height: auto;
    width: auto;
    display: block;
    aspect-ratio: 1 / 1; 
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      aspect-ratio: 1.5 / 1; 
    }
  }
`;

const LandingPage = () => {
  const images = [
    { src: "/minenet_utsa.jpg", alt: "Image 1", caption: "Oral Presentation of UTSA MinEnet Paper at UTSA AI Summit" },
    { src: "/speaker_acm_utd.jpeg", alt: "Image 2", caption: "ACM Research @ UTD as Mentor & Judge" },
    { src: "/career_mobility_tcna.jpg", alt: "Image 3", caption: "Career Fair TCNA Providing Insights Into Working Industry Machine Learning" },
    { src: "/acm_projects_utd.jpg", alt: "Image 4", caption: "ACM Projects Mentor Helping Student Led Software Projects" },
  ];

  const gridContainerStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(40ch, 1fr))", // Responsive columns
    gap: "0px",
    padding: "0px",
    margin: "-35px",
    width: "100vw",
    height: "100vh",
  };

  const gridItemStyles = {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  };

  const imageStyles ={
    width: "100%",
    height: "auto",
    objectFit: "cover",
    objectPosition: "center",
    filter: "grayscale(100%)",
    transition: "filter 0.3s ease-in-out, transform 0.3s ease-in-out",
  };

  const mobileBorderStyles = [
    {
      borderBottom: "2px solid #d4f1f4",
    },
    {
      borderTop: "2px solid #d4f1f4",
      borderBottom: "2px solid #d4f1f4",
    },
    {
      borderTop: "2px solid #d4f1f4",
      borderBottom: "2px solid #d4f1f4",
    },
    {
      borderTop: "2px solid #d4f1f4",
    }
  ];

  const desktopBorderStyles = [
    {
      borderRight: "2px solid #d4f1f4",
      borderBottom: "2px solid #d4f1f4",
    },
    {
      borderLeft: "2px solid #d4f1f4",
      borderBottom: "2px solid #d4f1f4",
    },
    {
      borderRight: "2px solid #d4f1f4",
      borderTop: "2px solid #d4f1f4",
    },
    {
      borderLeft: "2px solid #d4f1f4",
      borderTop: "2px solid #d4f1f4",
    }
  ];

  const hoverEffect = {
    filter: "grayscale(0%)",
    transform: "scale(1.05)",
  };

  const captionStyles = {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "white",
    textAlign: "center",
    padding: "5px 0",
    fontSize: "14px",
    transform: "translateY(100%)",
    transition: "transform 0.3s ease-in-out",
  };

  const captionVisibleStyles = {
    transform: "translateY(0)",
  };

  const [activeCaption, setActiveCaption] = useState(null);

  const handleImageFocus = (index) => {
    setActiveCaption(activeCaption === index ? null : index);
  };

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <GridContainer>
      {images.map((img, index) => (
        <div style={{...gridItemStyles, ...(isMobile ? mobileBorderStyles[index] : desktopBorderStyles[index])}}>
          <img
            src={img.src}
            alt={img.alt}
            style={imageStyles}
            onMouseOver={(e) => {
              e.currentTarget.style.filter = hoverEffect.filter;
              e.currentTarget.style.transform = hoverEffect.transform;
              handleImageFocus(index);
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.filter = imageStyles.filter;
              e.currentTarget.style.transform = "scale(1)";
              handleImageFocus(index);
            }}
          />
          <div
            style={{
              ...captionStyles,
              ...(activeCaption === index ? captionVisibleStyles : {}),
            }}
          >
            {img.caption}
          </div>
        </div>
      ))}
    </GridContainer>
  );
};

export default LandingPage;
